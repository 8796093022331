import getEnv from '@/util/env'
import { ExportForm } from '@/classes/ExportForm'
import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import CertifiedSmsService from '@/services/certified-sms.service'
import DateRange from '@/components/DateRange/DateRange.vue'
import EventBus from '@/util/EventBus'
import { Country } from '@/util/Country.js'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'

export default {
  name: 'CertifiedSms',
  components: { DateRange, HeaderTopDashboard },
  data () {
    return {
      show: false,
      globalControl: new GlobalControl(),
      loading: true,
      options: {},
      startDate: null,
      endDate: null,
      content: [],
      phone: '',
      itemsPerPage: 10,
      pageCount: 0,
      page: 1,
      totalRows: 0,
      countries: {},
      exportForm: new ExportForm(),
      debouncedInput: '',
      timeout: null,
      Country: Country,
    }
  },
  computed: {
    searchPhone: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.phone = val
        }, 700)
      },
    },
    headers () {
      return [
        { text: this.$t('Fecha'), value: 'date_sending' },
        { text: this.$t('Teléfono'), value: 'dest_addr' },
        { text: this.$t('País'), value: 'country' },
        { text: this.$t('Remitente'), value: 'source_addr' },
        { text: this.$t('Fecha Certificación'), value: 'date_certified' },
        { text: this.$t('Estado'), value: 'status' },
        { text: this.$t('Certificado'), value: 'file_name' },
      ]
    },
    params (nv) {
      return {
        ...this.options,
        query: [this.phone, this.startDate, this.endDate],
      }
    },
  },
  watch: {
    params: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  methods: {
    getDataFromApi () {
      EventBus.$emit('showLoading', true)
      const params = this.getParams()
      this.content = []
      this.loading = true
      CertifiedSmsService.getData(params)
        .then(
          (response) => {
            this.content = response.data.data ? JSON.parse(JSON.stringify(response.data.data)) : []
            this.itemsPerPage = parseInt(response.data.per_page)
            this.page = response.data.current_page
            this.totalRows = response.data.total
            this.countries = response.countries
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
          EventBus.$emit('showLoading', false)
        })
    },
    getParams () {
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        phone: this.phone,
        startDate: this.startDate,
        endDate: this.endDate,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      return params
    },

    getDownloadFile (item) {
      const urlParams = item.server_num + '/' + item.id_temp + '/' + item.name
      CertifiedSmsService.existFile(urlParams)
        .then(
          (response) => {
            if (response.exists) {
              const downloadUrl = 'api/certified-sms/pdf/' + urlParams
              const user = JSON.parse(localStorage.getItem('user'))
              const url = getEnv('VUE_APP_API_URL') + downloadUrl + '?token=' + user.token
              window.location.href = url
            } else {
              this.show = true
            }
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
        })
    },
    selectDates (dates) {
      this.startDate = dates[0]
      this.endDate = dates[1]
    },
    getColumns () {
      const columns = []
      this.headers.forEach(function (column) {
        columns.push(column.value)
      })
      return columns
    },
    setExportData () {
      this.exportForm.setFromParamsAndColums(this.getParams(), this.getColumns())
    },
    exportCsv () {
      this.setExportData()
      const url = getEnv('VUE_APP_API_URL') + 'api/certified-sms/export-csv?' + this.exportForm.getUrlParams()
      window.location.href = url
    },
  },
  created () {
    this.startDate = this.globalControl.initDate
    this.endDate = this.globalControl.endDate
  },
}
