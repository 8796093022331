export class ExportForm {
  action = ''
  startDate = ''
  endDate
  columns = ''
  searchTerm = ''
  sort = ''
  token = ''
  campaignId = ''
  shareHash = ''
  phone = ''

  setFromParamsAndColums (params, columns, shareHash = null) {
    this.startDate = params.initDate ? params.initDate : params.startDate
    this.endDate = params.endDate
    this.columns = JSON.stringify(columns)
    this.searchTerm = params.searchTerm
    this.sort = params.sortBy ? params.sortBy : ''
    this.shareHash = shareHash
    const user = JSON.parse(localStorage.getItem('user'))
    this.token = user ? user.token : null
    this.campaignId = params.campaignId
    this.phone = params.phone ? params.phone : ''
  }

  getUrlParams () {
    return new URLSearchParams(this).toString()
  }
}
